import { Link as RouterLink } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import Footer from '../../../components/Footer/Footer';
import { BottomBox, MainBox, Stepper, SvgBox, TextBox, TopBox } from './Introduction3.styles';

const Introduction3 = () => {
  return (
    <MainBox>
      <TopBox />
      <BottomBox>
        <SvgBox>
          <img
            alt='glass of beer'
            src={'/images/glass-beer.svg'}
          />
          <img
            alt='glass'
            src={'/images/glass.svg'}
          />
          <img
            alt='can'
            src={'/images/can.svg'}
          />
          <img
            alt='bottle of beer'
            src={'/images/bottle-beer.svg'}
          />
        </SvgBox>
        <TextBox>
          <Typography
            variant='h1'
            color='white'
          >
            Disfruta con amigos
          </Typography>
          <Typography
            variant='h4'
            color='white'
          >
            Invitalos a disfrutar la experiencia.
          </Typography>
        </TextBox>
        <Stepper
          activeStep={2}
          backButton={null}
          nextButton={null}
          variant='dots'
          steps={3}
        />
        <Button
          component={RouterLink}
          to='/sign-up'
          variant='contained'
          color='secondary'
        >
          Comenzar
        </Button>
        <Footer />
      </BottomBox>
    </MainBox>
  );
}

export default Introduction3;