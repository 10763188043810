import TicketSkeleton from '../TicketSkeleton/TicketSkeleton';
import { MainBox } from './MyCouponsSkeleton.styles';

const MyCouponsSkeleton = () => {
  return (
    <MainBox>
      <TicketSkeleton></TicketSkeleton>
      <TicketSkeleton></TicketSkeleton>
      <TicketSkeleton></TicketSkeleton>
    </MainBox>
  );
};

export default MyCouponsSkeleton;
