import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const BottomBox = styled(Box)`
  position: relative;
  display: flex;
  min-height: calc(100vh - 469px);
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.background.paper};
  border-radius: 15px 15px 0 0;
`;

export const QrBox = styled(Box)`
  display: flex;
  flex-direction: row;
  min-height: 270px;
  margin: 10px 0;

  img {
    width: 250px;
    height: 250px;
    color: black;
    background: white;
  }
`;

export const CodeBox = styled(Box)`
  width: 50px;
  height: 250px;
  background-color: ${(props) => props.theme.palette.grey[400]};
  position: relative;

  .coupon-text, .coupon-code-text  {
    font-size: 37px;
    transform: rotate(-90deg);
    text-transform: uppercase;
    position: absolute;
    color: ${(props) => props.theme.palette.grey[800]};
  }

  .coupon-text {
    font-weight: 100;
    bottom: 52px;
    left: -7px;
  }

  .coupon-code-text {
    bottom: 142px;
    left: -15px;
  }
`;
