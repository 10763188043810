import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const HeroBox = styled(Box)`
  margin-bottom: 10px;
`;

export const DescriptionBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export const DescriptionWrapper = styled(Box)`
  width: 50vw;
  height: 50vw;
  padding: 15px;
`

export const CarouselBox = styled(Box)`
  width: 50vw;
  height: 50vw;
`

export const TicketsBox = styled(Box)`
  width: 100%;
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const LocationBox = styled(Box)`
  width: 100%;
  margin-top: 20px;
`;