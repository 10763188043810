import { Skeleton } from '@mui/material';

const PromotionHeaderSkeleton = () => {
  return (
    <Skeleton
      variant='rounded'
      animation='wave'
      width={'100%'}
      height={489}
    />
  );
};

export default PromotionHeaderSkeleton;
