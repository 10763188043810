import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const BottomBox = styled(Box)`
  position: relative;
  min-height: calc(100vh - 469px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.background.paper};
  border-radius: 15px 15px 0 0;

  .redeem-button {
    background-color: ${(props) => props.theme.palette.secondary.main};
    color: white;
    font-family: 'Tungsten';
    font-weight: 800;
    font-size: 40px;
    line-height: 40px;
    padding-top: 10px;
  }
`;
