import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { PlaceOutlined } from '@mui/icons-material';
import { Button, Link, Typography } from '@mui/material';
import { Coupon, Promotion } from '../../globals/types';
import { useCoupon } from '../../hooks/useCoupon';
import { usePromotion } from '../../hooks/usePromotion';
import PromotionHeaderSkeleton from '../skeletons/PromotionHeaderSkeleton/PromotionHeaderSkeleton';
import { CrownBox, MainBox } from './PromotionHeader.styles';

interface PromotionHeaderProps {
  promotionId?: string;
  couponId?: string;
}

const PromotionHeader = ({ promotionId, couponId }: PromotionHeaderProps) => {
  if (!promotionId && !couponId) return <Typography variant='h1'>Ups... Algo salió mal ...</Typography>;
  const { fetchPromotion } = usePromotion();
  const { fetchCoupon } = useCoupon();

  const { data, isLoading, error } = useQuery<Promotion | Coupon>(
    ['fetchPromotion', promotionId],
    () => (promotionId ? fetchPromotion(promotionId) : fetchCoupon(couponId as string)),
    {
      staleTime: 600000,
    },
  );

  const getTitle = (): string => {
    return couponId ? (data as Coupon).promotion.title : (data as Promotion).title;
  };

  const getSubTitle = (): string => {
    return couponId ? (data as Coupon).promotion.subtitle : (data as Promotion).subtitle;
  };

  const getDueDate = (): string => {
    const dueDate = new Date(couponId ? (data as Coupon).dueDate : (data as Promotion).dueDate);
    return `Vencimiento: ${dueDate.toLocaleDateString()}`;
  };

  const getDescription = (): string => {
    return couponId ? (data as Coupon).promotion.description : (data as Promotion).description;
  };

  const getShopSlug = (): string => {
    return couponId ? (data as Coupon).promotion.shop.slug : (data as Promotion).shop.slug;
  };

  const getShopName = (): string => {
    return couponId ? (data as Coupon).promotion.shop.name : (data as Promotion).shop.name;
  };

  if (isLoading) return <PromotionHeaderSkeleton />;
  if (error || !data) return <Typography variant='h1'>Ups... Algo salió mal ...</Typography>;

  return (
    <MainBox>
      <Typography
        variant='h1'
        className='title'
      >
        {couponId ? 'Usar' : 'Canjear'} Cupón
      </Typography>
      <CrownBox>
        <Typography
          variant='h3'
          className='promotion-title'
        >
          {getTitle()}
        </Typography>
        <Typography
          variant='h3'
          className='promotion-subtitle'
        >
          {getSubTitle()}
        </Typography>
      </CrownBox>
      <Typography className='due-date'>{getDueDate()}</Typography>
      <Typography
        variant='h4'
        className='description'
      >
        {getDescription()}. Ver bases y condiciones haciendo click{' '}
        <Link
          color='#ffffff'
          href='#'
        >
          aquí
        </Link>
        .
      </Typography>
      <Button
        variant='outlined'
        color='secondary'
        component={RouterLink}
        to={`/shops/${getShopSlug()}`}
      >
        <PlaceOutlined className='place-icon' />
        <Typography
          variant='h5'
          color='white'
        >
          {getShopName()}
        </Typography>
      </Button>
    </MainBox>
  );
};

export default PromotionHeader;
