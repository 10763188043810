import { useNavigate } from 'react-router-dom';
import { PlaceOutlined } from '@mui/icons-material';
import { Fab, Typography } from '@mui/material';
import { Coupon, Promotion } from '../../globals/types';
import { useCoupon } from '../../hooks/useCoupon';
import {
  BottomBand,
  CrownBox,
  MainBox,
  TicketLeftSide,
  TicketRightSide,
  TicketRightSideShopNameBox,
  TopBand,
} from './Ticket.styles';

interface Props {
  promotion?: Promotion;
  coupon?: Coupon;
}

const Ticket = ({ promotion, coupon }: Props) => {
  const navigate = useNavigate();
  const { isActive } = useCoupon();

  const currentPromotion = coupon ? coupon.promotion : promotion;

  const openTicket = () => {
    if (promotion) return navigate(`/promotions/${promotion._id}`);
    if (coupon && isActive(coupon)) return navigate(`/coupons/${coupon._id}`);
  };

  const goToShop = () => {
    return navigate(`/shops/${promotion ? promotion.shop.slug : coupon?.promotion.shop.slug}`);
  };

  const getDueDate = () => {
    if (coupon) return new Date(coupon.dueDate).toLocaleDateString('es-AR');
    if (promotion) return new Date(promotion.dueDate).toLocaleDateString('es-AR');
  };

  return (
    <MainBox className={coupon && !isActive(coupon) ? 'inactive' : undefined}>
      <TicketLeftSide>
        <TopBand onClick={goToShop}>
          <PlaceOutlined className='place-icon' />
          <Typography
            variant='h5'
            color='white'
          >
            {currentPromotion?.shop.name}
          </Typography>
        </TopBand>
        <CrownBox onClick={openTicket}>
          <Typography
            variant='h3'
            color='primary'
            lineHeight='45px'
          >
            {currentPromotion?.title}
          </Typography>
          <Typography
            variant='h3'
            color='secondary'
            textTransform='uppercase'
            fontSize='14px'
            maxWidth='50px'
          >
            {currentPromotion?.subtitle}
          </Typography>
        </CrownBox>
        <Fab
          className='redeem-ticket'
          variant='extended'
          onClick={openTicket}
        >
          Usar Cupón
        </Fab>
        <BottomBand>
          <Typography
            variant='h4'
            fontSize='11px'
          >
            *Las fotos son de carácter ilustrativo
          </Typography>
        </BottomBand>
      </TicketLeftSide>
      <>
        {!coupon?.redeemed && (
          <TicketRightSide>
            <img
              alt='barcode'
              src={'/images/barcode.svg'}
            />
            <TicketRightSideShopNameBox>
              <Typography
                variant='h2'
                className='due-date'
              >
                vencimiento: {getDueDate()}
              </Typography>
            </TicketRightSideShopNameBox>
          </TicketRightSide>
        )}
      </>
    </MainBox>
  );
};

export default Ticket;
