import { Skeleton } from '@mui/material';
import TicketSkeleton from '../TicketSkeleton/TicketSkeleton';
import {
  AddressBox,
  HeroBox,
  HighlightedShopBox,
  HighlightedShopsBox,
  ImageBox,
  LocationBandBox,
  TicketsBox,
} from './HomeSkeleton.styles';

const HomeSkeleton = () => {
  return (
    <>
      <HeroBox>
        <Skeleton
          variant='rectangular'
          animation='wave'
          height='320px'
        />
      </HeroBox>
      <LocationBandBox>
        <Skeleton
          variant='rectangular'
          animation='wave'
          height='38px'
        />
      </LocationBandBox>
      <Skeleton
        variant='text'
        width='230px'
        sx={{ fontSize: '25px', ml: '10%' }}
      />
      <TicketsBox>
        <TicketSkeleton />
        <TicketSkeleton />
      </TicketsBox>
      <ImageBox>
        <Skeleton
          variant='rectangular'
          animation='wave'
          height='160px'
        />
      </ImageBox>
      <Skeleton
        variant='text'
        width='200px'
        sx={{ fontSize: '25px', ml: '10%' }}
      />
      <HighlightedShopsBox>
        {[...Array(4)].map((e, i) => (
          <HighlightedShopBox key={i}>
            <Skeleton
              variant='rectangular'
              animation='wave'
              height='150px'
              width='150px'
              sx={{ ml: '5px', mr: '5px' }}
            />
          </HighlightedShopBox>
        ))}
      </HighlightedShopsBox>
      <AddressBox>
        <Skeleton
          variant='text'
          width='200px'
          sx={{ fontSize: '25px' }}
        />
      </AddressBox>
      <Skeleton
        variant='rectangular'
        height='200px'
        width='100%'
      />
    </>
  );
};

export default HomeSkeleton;
