import styled from '@emotion/styled';
import { Box, Skeleton } from '@mui/material';

export const HeroBox = styled(Box)`
  height: 320px;
  clip-path: ellipse(100% 90% at 70% 10%);
`;

export const LocationBandBox = styled(Box)`
  width: 100%;
  position: absolute;
  top: 260px;
`;

export const TicketsBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ImageBox = styled(Box)`
  margin-top: 10px;
`;

export const HighlightedShopsBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  margin-bottom: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const HighlightedShopBox = styled(Box)`
  width: 160px;
  height: 160px;
  padding: 5px;
`

export const AddressBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
`;

