import QRCode from 'qrcode';
import { useLayoutEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Button, Typography } from '@mui/material';
import { Coupon as CouponType } from '../../../globals/types';
import Footer from '../../../components/Footer/Footer';
import Hamburger from '../../../components/Hamburger/Hamburger';
import PromotionHeader from '../../../components/PromotionHeader/PromotionHeader';
import PromotionHeaderSkeleton from '../../../components/skeletons/PromotionHeaderSkeleton/PromotionHeaderSkeleton';
import { useCoupon } from '../../../hooks/useCoupon';
import { BottomBox, CodeBox, QrBox } from './Coupon.styles';

const Coupon = () => {
  const { id } = useParams();
  if (!id) return <Typography variant='h1'>Ups... Por favor introduce un Cupón válido...</Typography>;

  const { fetchCoupon } = useCoupon();
  const { data: coupon, isLoading, error } = useQuery<CouponType>([`fetchCoupon-${id}`], () => fetchCoupon(id));
  const [qr, setQr] = useState<string>();

  useLayoutEffect(() => {
    if (!qr && coupon?._id) {
      generateQr(coupon?._id);
    }
  });

  const generateQr = (couponId: string) => {
    QRCode.toDataURL(`${process.env.REACT_APP_BASE_URL}/coupons/${couponId}/details`, (err: any, url: string) => {
      if (err) throw new Error(err);
      setQr(url);
    });
  };

  if (isLoading) return <PromotionHeaderSkeleton />;
  if (error || !coupon) return <Typography variant='h1'>Ups... Algo salió mal ...</Typography>;

  return (
    <>
      <Hamburger />
      <PromotionHeader couponId={coupon._id} />
      <BottomBox>
        {qr && (
          <>
            <QrBox>
              <CodeBox>
                <Typography
                  variant='h3'
                  className='coupon-text'
                >
                  Cupón
                </Typography>
                <Typography
                  variant='h3'
                  className='coupon-code-text'
                >
                  {coupon.slug}
                </Typography>
              </CodeBox>
              <img src={qr} />
            </QrBox>
            <Button
              variant='contained'
              color='primary'
              component={RouterLink}
              to='/success'
            >
              Continuar
            </Button>
          </>
        )}
        <Footer />
      </BottomBox>
    </>
  );
};

export default Coupon;
