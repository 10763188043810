import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Button, Skeleton, Typography } from '@mui/material';
import { Coupon } from '../../../globals/types';
import { useCoupon } from '../../../hooks/useCoupon';
import { MainBox, Section } from './CouponDetails.styles';

const CouponDetails = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { fetchCouponBySlug, approveCoupon } = useCoupon();
  const {
    data: coupon,
    isLoading,
    error,
    refetch,
  } = useQuery<Coupon>(['fetchCoupon', slug], () => fetchCouponBySlug(slug as string));

  if (isLoading) return <Skeleton />;
  if (error || !coupon) return <Typography variant='h1'>Ups... Algo salió mal ...</Typography>;

  const handleApproveCoupon = async () => {
    await approveCoupon(coupon?._id as string);
    refetch();
  };

  return (
    <MainBox>
      <Typography variant='h1'> Detalles del cupón {slug} </Typography>
      <Section>
        <Typography variant='h2'> Promoción </Typography>
        <Typography> ID de promoción: {coupon?.promotion._id}</Typography>
        <Typography> Título: {`${coupon?.promotion.title} ${coupon?.promotion.subtitle} `}</Typography>
        <Typography> Descripción: {coupon?.promotion.description}</Typography>
        <Typography> Fecha de vencimiento: {new Date(coupon?.promotion.dueDate).toLocaleDateString()}</Typography>
      </Section>
      <Section>
        <Typography variant='h2'> Usuario </Typography>
        <Typography> ID de usuario: {coupon?.user._id}</Typography>
        <Typography> Nombre: {`${coupon?.user.firstName} ${coupon?.user.lastName} `}</Typography>
      </Section>
      <Section>
        <Typography variant='h2'> Cupón </Typography>
        <Typography> Fecha de canje: {coupon?.redeemedAt || 'Todavía no se ha canjeado'}</Typography>
        <Typography> Fecha de vencimiento: {new Date(coupon?.dueDate).toLocaleDateString()}</Typography>
        <Typography> Nombre: {`${coupon?.user.firstName} ${coupon?.user.lastName} `}</Typography>
      </Section>
      <Section>
        <Button
          variant='contained'
          disabled={coupon.redeemed}
          onClick={handleApproveCoupon}
        >
          {coupon.redeemed ? 'aprobado' : 'aprobar'}
        </Button>
        <Button
          variant='contained'
          color='secondary'
          onClick={() => navigate(-1)}
          sx={{ mt: '20px' }}
        >
          Volver
        </Button>
      </Section>
    </MainBox>
  );
};
export default CouponDetails;
