import { Navigate, Route, Routes } from 'react-router-dom';
import Coupon from '../pages/user/Coupon/Coupon';
import Home from '../pages/user/Home/Home';
import MyCoupons from '../pages/user/MyCoupons/MyCoupons';
import Promotion from '../pages/user/Promotion/Promotion';
import Shop from '../pages/user/Shop/Shop';
import Success from '../pages/user/Success/Success';

const UserRoutes = () => {
  return (
    <Routes>
      <Route
        path='/home'
        element={<Home />}
      />
      <Route
        path='shops/:slug'
        element={<Shop />}
      />
      <Route
        path='success'
        element={<Success />}
      />
      <Route
        path='promotions/:id'
        element={<Promotion />}
      />
      <Route
        path='my-coupons'
        element={<MyCoupons />}
      />
      <Route
        path='coupons/:id'
        element={<Coupon />}
      />
      <Route
        path='*'
        element={
          <Navigate
            replace
            to='/home'
          />
        }
      />
    </Routes>
  );
};

export default UserRoutes;
