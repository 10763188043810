import { Typography } from '@mui/material';
import { MainBox } from './Footer.styles';

const Footer = () => {
  return (
    <MainBox>
      <Typography
        variant='h5'
        color='white'
      >
        Desarrollado por CircleMate™
      </Typography>
    </MainBox>
  );
};

export default Footer;