import GoogleMapReact from 'google-map-react';
import { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Facebook, Instagram, LocationOn, Phone, PlaceOutlined, WhatsApp } from '@mui/icons-material';
import { ButtonBase, Typography } from '@mui/material';
import { Promotion, Shop as ShopType } from '../../../globals/types';
import Footer from '../../../components/Footer/Footer';
import Hamburger from '../../../components/Hamburger/Hamburger';
import Ticket from '../../../components/Ticket/Ticket';
import ShopSkeleton from '../../../components/skeletons/ShopSkeleton/ShopSkeleton';
import { useShop } from '../../../hooks/useShop';
import {
  AddressBox,
  CarouselItem,
  DescriptionBox,
  Header,
  HeroBox,
  ImagesCarouselBox,
  LocationBox,
  MainBox,
  MapsBox,
  OpeningHoursAndPhone,
  OpeningHoursBox,
  OpeningHoursWrapper,
  PhoneBox,
  Quilmes,
  SocialNetworkIcons,
  StickySocialNetworks,
  TicketsBox,
} from './Shop.styles';

interface itemProps {
  key: number;
  image: string;
}

interface ShopProps {
  shop: ShopType;
  promotions: Promotion[];
}

const ItemImagesCarousel = (props: itemProps) => <CarouselItem sx={{ backgroundImage: `url(${props.image})` }} />;

const Shop = () => {
  const { slug } = useParams();
  if (!slug) return <Typography variant='h1'>Ups... Por favor introduce un Shop válido...</Typography>;

  const { fetchShopBySlug, fetchActivePromotions, isOpen, getOpeningHoursText } = useShop();
  const [coords, setCoords] = useState<GoogleMapReact.Props>();
  const [mapsApiKey] = useState<string>(process.env.REACT_APP_MAPS_API_KEY || '');
  const {
    data: shopData,
    isLoading,
    error,
  } = useQuery<ShopProps>(['fetchShop', slug], () => fetchShopData(slug), {
    staleTime: 600000,
  });

  const fetchShopData = async (slug: string) => {
    const shop = await fetchShopBySlug(slug);
    if (!shop._id) throw new Error('No pudimos encontrar ese shop');
    const promotions = await fetchActivePromotions(shop._id);
    setCoords({
      center: {
        lat: shop.location.coordinates[0],
        lng: shop.location.coordinates[1],
      },
      zoom: 17,
    });
    return { shop: shop, promotions: promotions };
  };

  if (isLoading) return <ShopSkeleton />;
  if (error || !shopData) return <Typography variant='h1'>Ups... Algo salió mal ...</Typography>;

  return (
    <MainBox>
      <Hamburger />
      <HeroBox sx={{ backgroundImage: `url(${shopData?.shop.heroImage})` }}>
        <Header>
          <Quilmes />
        </Header>
        <StickySocialNetworks>
          <Typography variant='h1'>{shopData?.shop.name}</Typography>
          <SocialNetworkIcons>
            {shopData?.shop.whatsApp ? (
              <ButtonBase
                href={shopData?.shop.whatsApp}
                target='_blank'
                rel='noopener noreferrer'
              >
                <WhatsApp />
              </ButtonBase>
            ) : (
              ''
            )}
            {shopData?.shop.instagram ? (
              <ButtonBase
                href={'https://instagram.com/' + shopData?.shop.instagram}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Instagram />
              </ButtonBase>
            ) : (
              ''
            )}
            {shopData?.shop.facebook ? (
              <ButtonBase
                href={'https://facebook.com/' + shopData?.shop.facebook}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Facebook />
              </ButtonBase>
            ) : (
              ''
            )}
          </SocialNetworkIcons>
        </StickySocialNetworks>
        <OpeningHoursAndPhone className={isOpen(shopData.shop) ? '' : 'closed'}>
          <Typography className='open-or-closed'>{isOpen(shopData.shop) ? 'Abierto' : 'Cerrado'}</Typography>
          <OpeningHoursBox>
            <OpeningHoursWrapper className={getOpeningHoursText(shopData.shop).length > 2 ? 'multiple-lines' : ''}>
              {getOpeningHoursText(shopData.shop).map((i, key) => (
                <Typography key={key}>{i}</Typography>
              ))}
            </OpeningHoursWrapper>
          </OpeningHoursBox>
          {shopData?.shop.phoneNumber ? (
            <PhoneBox>
              <Phone></Phone>
              <ButtonBase href={`tel:${shopData?.shop.phoneNumber}`}>
                <Typography className='phone-number'>{shopData?.shop.phoneNumber}</Typography>
              </ButtonBase>
            </PhoneBox>
          ) : (
            ''
          )}
        </OpeningHoursAndPhone>
      </HeroBox>
      <DescriptionBox>
        <Typography
          variant='h4'
          className='description'
        >
          {shopData?.shop.description}
        </Typography>
        <ImagesCarouselBox>
          <Carousel indicatorContainerProps={{ style: { marginTop: 0 } }}>
            {shopData?.shop.otherImages.map((image, i) => (
              <ItemImagesCarousel
                key={i}
                image={image}
              />
            ))}
          </Carousel>
        </ImagesCarouselBox>
      </DescriptionBox>
      <TicketsBox>
        {shopData?.promotions.map((promotion, i) => (
          <Ticket
            key={i}
            promotion={promotion}
          />
        ))}
      </TicketsBox>
      <LocationBox>
        <AddressBox>
          <PlaceOutlined />
          <Typography>{shopData?.shop.address + ', ' + shopData?.shop.city + ', ' + shopData?.shop.state}</Typography>
        </AddressBox>
        <MapsBox>
          <GoogleMapReact
            bootstrapURLKeys={{ key: mapsApiKey }}
            defaultCenter={coords?.center}
            defaultZoom={17}
            zoom={coords?.zoom}
            yesIWantToUseGoogleMapApiInternals
          >
            <>
              <LocationOn className='location-point' />
            </>
          </GoogleMapReact>
        </MapsBox>
      </LocationBox>
      <Footer />
    </MainBox>
  );
};

export default Shop;
