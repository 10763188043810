import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MainBox = styled(Box)`
  width: 100%;
  position: relative;
`;

export const HeroBox = styled(Box)`
  position: relative;
  height: 300px;
  background-color: black;
  background-position: bottom center;
  background-size: auto calc(100% - 72px);
  background-repeat: no-repeat;
`;

export const Header = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 140px;
  background-color: ${(props) => props.theme.palette.tertiary.main};
  clip-path: ellipse(70% 80% at 40% 10%);
`;

export const Quilmes = styled(Box)`
  width: 124px;
  height: 60px;
  margin-top: 35px;
  background: url(/images/quilmes.svg) no-repeat;
  background-size: contain;
`;

export const StickySocialNetworks = styled(Box)`
  min-width: 170px;
  background-color: #3e3e3e;
  opacity: 0.95;
  position: absolute;
  right: 0;
  top: 107px;
  z-index: 2;
  padding: 3px 7px 0 7px;
  display: flex;
  flex-direction: row;

  h1 {
    color: ${(props) => props.theme.palette.tertiary.dark};
    font-size: 28px;
  }
`;

export const SocialNetworkIcons = styled(Box)`
  margin-left: 10px;
  display: flex;
  align-items: center;
  padding-bottom: 3px;

  svg {
    height: 1.1em;
    width: 1.1em;
    margin: 0 2px;
    color: white;
  }
`;

export const OpeningHoursAndPhone = styled(Box)`
  width: 100%;
  background-color: ${(props) => props.theme.palette.primary.main};
  color: white;
  position: absolute;
  bottom: -30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .open-or-closed {
    font-family: Tungsten, serif;
    font-size: 30px;
    font-weight: lighter;
    text-transform: uppercase;
    margin: -6px 5px -9px 5px;
  }

  &.closed {
    background-color: #d00000;
  }
`;

export const OpeningHoursBox = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: calc(50% - 38px);
  max-height: 39px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const OpeningHoursWrapper = styled(Box)`
  &.multiple-lines {
    transform: translateY(20%);
    animation: moveSlideshow 6s linear infinite;

    @keyframes moveSlideshow {
      100% {
        transform: translateY(-100%);
      }
    }
  }

  p {
    font-family: Lato, monospace;
    font-size: 13px;
  }
`;

export const PhoneBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
  max-width: calc(50% - 38px);

  .phone-number {
    font-family: Lato, monospace;
    font-size: 13px;
    margin-left: 5px;
  }
`;

export const DescriptionBox = styled(Box)`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .description {
    width: 50%;
    padding: 10px;
    font-family: Publico, sans-serif;
  }
`;

export const ImagesCarouselBox = styled(Box)`
  width: 50%;
  height: 50vw;
`;

export const CarouselItem = styled(Box)`
  width: 100%;
  height: 50vw;
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
`;

export const TicketsBox = styled(Box)`
  width: 100%;
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const LocationBox = styled(Box)`
  width: 100%;
  margin-top: 20px;
`;

export const AddressBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
`;

export const MapsBox = styled(Box)`
  width: 100%;
  height: 200px;

  .location-point {
    width: 2em;
    height: 2em;
    color: ${(props) => props.theme.palette.primary.main};
  }
`;
