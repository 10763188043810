import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AirplanemodeActive } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Coupon, CouponStatus } from '../../../globals/types';
import Footer from '../../../components/Footer/Footer';
import Hamburger from '../../../components/Hamburger/Hamburger';
import Ticket from '../../../components/Ticket/Ticket';
import MyCouponsSkeleton from '../../../components/skeletons/MyCouponsSkeleton/MyCouponsSkeleton';
import { useCoupon } from '../../../hooks/useCoupon';
import {
  ActiveCouponsButton,
  BottomBox,
  ButtonsBox,
  InactiveCouponsButton,
  MyCouponsButton,
  PlaneBox,
  Quilmes,
  TicketsBox,
  TopBox,
} from './MyCoupons.styles';

const MyCoupons = () => {
  const { fetchUserCoupons } = useCoupon();
  const [couponsStatus, setCouponsStatus] = useState<CouponStatus>('active');
  const {
    data: coupons,
    isLoading,
    error,
  } = useQuery<Coupon[]>(['fetchCoupons', couponsStatus], () => fetchUserCoupons(couponsStatus));

  const handleStatusSelection = (status: CouponStatus) => {
    setCouponsStatus(status);
  };

  if (error) return <Typography variant='h1'>Ups... Algo salió mal ...</Typography>;

  return (
    <>
      <Hamburger />
      <TopBox>
        <Quilmes />
      </TopBox>
      <ButtonsBox>
        <MyCouponsButton
          onClick={() => handleStatusSelection('active')}
          className='top-box-button'
        >
          Mis Cupones
        </MyCouponsButton>
        <ActiveCouponsButton
          onClick={() => handleStatusSelection('active')}
          className='top-box-button'
        >
          Activos
        </ActiveCouponsButton>
        <InactiveCouponsButton
          onClick={() => handleStatusSelection('inactive')}
          className='top-box-button'
        >
          Inactivos
        </InactiveCouponsButton>
      </ButtonsBox>
      <BottomBox>
        <PlaneBox>
          <AirplanemodeActive className={`airplane-icon ${couponsStatus}`}></AirplanemodeActive>
          <Typography className='circle-text'>IGR</Typography>
        </PlaneBox>
        {isLoading ? (
          <MyCouponsSkeleton />
        ) : (
          <TicketsBox>
            {couponsStatus === 'active' ? (
              coupons?.map((coupon, i) => (
                <Ticket
                  key={i}
                  coupon={coupon}
                />
              ))
            ) : (
              <>
                <Typography className='inactive-coupons-title'>Vencidos</Typography>
                {coupons
                  ?.filter((coupon) => !coupon.redeemed)
                  .map((coupon, i) => (
                    <Ticket
                      key={i}
                      coupon={coupon}
                    />
                  ))}
                <Typography className='inactive-coupons-title redeemed'>Canjeados</Typography>
                {coupons
                  ?.filter((coupon) => coupon.redeemed)
                  .map((coupon, i) => (
                    <Ticket
                      key={i}
                      coupon={coupon}
                    />
                  ))}
              </>
            )}
          </TicketsBox>
        )}
        <Footer />
      </BottomBox>
    </>
  );
};

export default MyCoupons;
