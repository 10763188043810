import { Navigate, Route, Routes } from 'react-router-dom';
import SignIn from '../pages/auth/SignIn/SignIn';
import SignUp from '../pages/auth/SignUp/SignUp';
import Introduction1 from '../pages/introduction/Introduction1/Introduction1';
import Introduction2 from '../pages/introduction/Introduction2/Introduction2';
import Introduction3 from '../pages/introduction/Introduction3/Introduction3';
import MinorDenied from '../pages/introduction/MinorDenied/MinorDenied';
import Welcome from '../pages/introduction/Welcome/Welcome';

const PublicRoutes = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={<Welcome />}
      />
      <Route
        path='introduction-1'
        element={<Introduction1 />}
      />
      <Route
        path='introduction-2'
        element={<Introduction2 />}
      />
      <Route
        path='introduction-3'
        element={<Introduction3 />}
      />
      <Route
        path='minor-denied'
        element={<MinorDenied />}
      />
      <Route
        path='sign-in'
        element={<SignIn />}
      />
      <Route
        path='sign-up'
        element={<SignUp />}
      />
      <Route
        path='*'
        element={
          <Navigate
            replace
            to='/sign-in'
          />
        }
      />
    </Routes>
  );
};

export default PublicRoutes;
