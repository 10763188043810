import Search from '@mui/icons-material/Search';
import { Autocomplete, TextField } from '@mui/material';
import { Shop } from '../../globals/types';
import { SearchWrapper } from './Search.styles';

interface SearchProps {
  shops: Shop[];
}

const SearchAppBar = ({ shops }: SearchProps) => {
  return (
    <SearchWrapper>
      <Autocomplete
        options={shops.map((shop) => shop.name)}
        className='input-search'
        freeSolo={true}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Buscar Restaurante, Cantina, Bar'
            InputProps={{
              ...params.InputProps,
              startAdornment: <Search color='disabled' />,
            }}
          />
        )}
      />
    </SearchWrapper>
  );
};

export default SearchAppBar;
