import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SearchWrapper = styled(Box)`
  height: 40px;
  width: 80%;
  position: relative;
  display: flex;
  border-radius: 12px;
  background-color: white;
  align-items: center;
  margin: 10px 0 20px;
  color: grey;

  .input-search {
    font-size: 14px;
  }
`;
