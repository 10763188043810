import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MainBox = styled(Box)`
  padding: 20px;
  text-align: center;
`;

export const Section = styled(Box)`
  margin-top: 20px;
`;