import { MainBox, QuilmesCervezaArgentina, SealPasaporteBlue } from './AuthenticationHeader.styles';

const AuthenticationHeader = () => {
  return (
    <MainBox>
      <QuilmesCervezaArgentina />
      <SealPasaporteBlue />
    </MainBox>
  );
};

export default AuthenticationHeader;
