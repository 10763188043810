import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Fab, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import Footer from '../../../components/Footer/Footer';
import { BottomBox, MainBox, QuilmesCervezaArgentina, Seal, TextBox, TopBox } from './Welcome.styles';

const Welcome = () => {
  const [language, setLanguage] = useState<string>('spanish');

  const handleChange = (event: SelectChangeEvent<typeof language>) => {
    setLanguage(event.target.value);
  };

  return (
    <MainBox>
      <TopBox>
        <QuilmesCervezaArgentina />
        <FormControl>
          <InputLabel>Idioma</InputLabel>
          <Select
            id='language'
            value={language}
            label='Idioma'
            placeholder='español'
            defaultValue={'spanish'}
            onChange={handleChange}
          >
            <MenuItem value={'spanish'}>Español</MenuItem>
            <MenuItem value={'french'}>Frances</MenuItem>
            <MenuItem value={'english'}>Ingles</MenuItem>
            <MenuItem value={'portuguese'}>Portugues</MenuItem>
          </Select>
        </FormControl>
      </TopBox>
      <BottomBox>
        <Seal />
        <TextBox>
          <Typography variant='h1'>Bienvenido</Typography>
          <Typography variant='h4'>Confirma tu edad para continuar</Typography>
        </TextBox>
        <Box>
          <Fab
            color='secondary'
            variant='extended'
            component={RouterLink}
            to='/minor-denied'
          >
            Soy menor
          </Fab>
          <Fab
            variant='extended'
            component={RouterLink}
            to='/introduction-1'
          >
            Soy mayor
          </Fab>
        </Box>
        <Footer />
      </BottomBox>
    </MainBox>
  );
};

export default Welcome;
