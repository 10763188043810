import { Coupon, CouponStatus } from '../globals/types';
import api from '../services/api';
import { useAuth } from '../context/AuthProvider';

const couponsApiPath = '/coupons';

const useCoupon = () => {
  const { user } = useAuth();

  const fetchUserCoupons = async (status: CouponStatus): Promise<Coupon[]> => {
    const { data } = await api.get(`${couponsApiPath}/by-user/${user?._id}/${status}`);
    return data;
  };

  const fetchShopCoupons = async (shopId: string): Promise<Coupon[]> => {
    const { data } = await api.get(`${couponsApiPath}/by-shop/${shopId}`);
    return data;
  };

  const generateCoupon = async (userId: string, promotionId: string): Promise<Coupon> => {
    const { data } = await api.post(couponsApiPath, { user: userId, promotion: promotionId });
    return data;
  };

  const fetchCoupon = async (couponId: string): Promise<Coupon> => {
    const { data } = await api.get(`${couponsApiPath}/${couponId}`);
    return data;
  };

  const fetchCouponBySlug = async (slug: string): Promise<Coupon> => {
    const { data } = await api.get(`${couponsApiPath}/by-slug/${slug}`);
    return data;
  };

  const approveCoupon = async (couponId: string) => {
    await api.patch(`${couponsApiPath}/${couponId}`, {
      redeemed: true,
      redeemedAt: new Date(),
    });
  };

  const isActive = (coupon: Coupon): boolean => {
    return !coupon.redeemed && new Date(coupon.dueDate) >= new Date();
  };

  return {
    fetchUserCoupons,
    fetchShopCoupons,
    generateCoupon,
    fetchCoupon,
    fetchCouponBySlug,
    isActive,
    approveCoupon,
  };
};

export { useCoupon };
