import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Button, Skeleton, Typography } from '@mui/material';
import { Shop } from '../../../globals/types';
import { useShop } from '../../../hooks/useShop';
import { MainBox, ShopsBox } from './MyShops.styles';

const ShopCoupons = () => {
  const { fetchUserShops } = useShop();
  const { data: shops, isLoading, error } = useQuery<Shop[]>(['shops'], () => fetchUserShops());

  if (isLoading) return <Skeleton />;
  if (error || !shops) return <Typography variant='h1'>Ups... Algo salió mal ...</Typography>;

  return (
    <MainBox>
      <Typography variant='h1'>My Shops</Typography>
      <ShopsBox>
        {shops.map((shop, i) => (
          <Button
            key={i}
            variant='outlined'
            component={Link}
            to={`/shop-promotions/${shop._id}`}
          >
            {shop.name}
          </Button>
        ))}
      </ShopsBox>
    </MainBox>
  );
};

export default ShopCoupons;
