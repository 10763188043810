import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MainBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.primary.main};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 15px 15px;
  padding-bottom: 15px;
  border-bottom: 2px dotted #ffff;

  .title {
    font-size: 32px;
    color: grey;
    margin: 25px auto 10px auto;
  }

  .due-date {
    font-family: Tungsten, serif;
    font-size: 26px;
    color: #999999;
    font-weight: lighter;
    text-transform: capitalize;
    line-height: 30px;
  }

  .description {
    color: ${(props) => props.theme.palette.grey[100]};
    text-align: center;
    max-width: 90%;
    margin: 10px auto;
  }
`;

export const CrownBox = styled(Box)`
  width: 240px;
  height: 240px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  top: -150px;
  background: url(/images/crown-cap.svg) no-repeat;
  background-size: contain;
  margin-bottom: 5px;

  .promotion-title {
    font-size: 110px;
    color: ${(props) => props.theme.palette.primary.light};
    line-height: 90px;
  }

  .promotion-subtitle {
    color: ${(props) => props.theme.palette.secondary.main};
    font-size: 34px;
    line-height: 30px;
    text-align: center;
    max-width: 70%;
  }
`;