import { Skeleton } from '@mui/material';
import TicketSkeleton from '../TicketSkeleton/TicketSkeleton';
import {
  CarouselBox,
  DescriptionBox,
  DescriptionWrapper,
  HeroBox,
  LocationBox,
  TicketsBox,
} from './ShopSkeleton.styles';

const ShopSkeleton = () => {
  return (
    <>
      <HeroBox>
        <Skeleton
          variant='rectangular'
          animation='wave'
          height={330}
        />
      </HeroBox>
      <DescriptionBox>
        <DescriptionWrapper>
          {[...Array(7)].map((e, i) => (
            <Skeleton
              key={i}
              variant='text'
              width={'100%'}
              sx={{ fontSize: '1rem' }}
            />
          ))}
        </DescriptionWrapper>
        <CarouselBox>
          <Skeleton
            variant='rectangular'
            animation='wave'
            height={'100%'}
          />
        </CarouselBox>
      </DescriptionBox>
      <TicketsBox>
        <TicketSkeleton />
        <TicketSkeleton />
      </TicketsBox>
      <LocationBox>
        <Skeleton
          variant='text'
          width={'80%'}
          sx={{ fontSize: '1rem', ml: '10%', mb: '10px' }}
        />
        <Skeleton
          variant='rectangular'
          animation='wave'
          height={330}
        />
      </LocationBox>
    </>
  );
}

export default ShopSkeleton;