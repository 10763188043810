import axios from 'axios';

const localStorageUserKey = '@pasaportequilmes:user';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (response) => response,
  (error) => (error.response.status === 401 ? cleanExpiredSession() : Promise.reject(error)),
);

const cleanExpiredSession = () => {
  delete api.defaults.headers.common.Authorization;
  localStorage.removeItem(localStorageUserKey);
};

export default api;
