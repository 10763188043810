import { createTheme } from '@mui/material/styles';
import { Theme as MuiTheme } from '@mui/material/styles/createTheme';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }

  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Theme extends MuiTheme {}
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#122337',
      light: '#283560',
    },
    secondary: {
      main: '#0f91d7',
    },
    tertiary: {
      light: '#ffde41',
      main: '#edc452',
      dark: '#a59143',
    },
    background: {
      default: '#f5f5f5',
      paper: '#E6E6E6',
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: 'Tungsten, sans-serif',
    h1: {
      fontFamily: 'Tungsten, sans-serif',
      fontWeight: 800,
      fontSize: 40,
      textTransform: 'uppercase',
      color: '#122337',
    },
    h2: {
      fontFamily: 'Tungsten',
      fontWeight: 500,
      fontSize: 36,
      color: '#0F91D7',
    },
    h4: {
      fontFamily: 'Publico, times-new-roman',
      fontWeight: 300,
      fontSize: 16,
      fontStyle: 'italic',
      color: '#122337',
    },
    h5: {
      fontFamily: 'Lato, arial',
      fontWeight: 300,
      fontSize: 13,
      color: '#122337',
    },
    h6: {
      fontFamily: 'Tungsten, sans-serif',
      fontWeight: 800,
      fontSize: 66,
      textTransform: 'uppercase',
      color: '#122337',
    },
    button: {
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiFab: {
      styleOverrides: {
        root: {
          fontFamily: 'Lato, sans-serif',
          fontWeight: 400,
          height: 32,
          fontSize: 13,
          backgroundColor: '#ffde41',
        },
        secondary: {
          background: 'none',
          border: '1px solid #ffde41',
          color: '#ffde41',
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Tungsten, sans-serif',
          letterSpacing: '1px',
          fontWeight: 400,
          fontSize: 20,
          height: 45,
          width: '90%',
        },
      },
    },
  },
});

export default theme;
