import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Button, Skeleton, Typography } from '@mui/material';
import { Promotion, Shop } from '../../../globals/types';
import { useShop } from '../../../hooks/useShop';
import { MainBox, PromotionsBox } from './ShopPromotions.styles';

const ShopPromotions = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { fetchShop, fetchPromotions } = useShop();
  const {
    data: promotions,
    isLoading: isLoadingPromotions,
    error: errorPromotions,
  } = useQuery<Promotion[]>(['shopCoupons', id], () => fetchPromotions(id as string));
  const {
    data: shop,
    isLoading: isLoadingShop,
    error: errorShop,
  } = useQuery<Shop>(['shop', id], () => fetchShop(id as string));

  if (isLoadingPromotions || isLoadingShop) return <Skeleton />;
  if (errorPromotions || errorShop) return <Typography variant='h1'>Ups... Algo salió mal ...</Typography>;

  return (
    <MainBox>
      <Typography variant='h1'>{shop?.name}</Typography>
      <PromotionsBox>
        {promotions?.map((promotion, i) => (
          <Button
            key={i}
            variant='outlined'
            component={Link}
            to={`/promotion-coupons/${promotion._id}`}
          >
            {`${promotion.title} ${promotion.subtitle}`}
          </Button>
        ))}
      </PromotionsBox>
      <Button
        variant='contained'
        color='secondary'
        onClick={() => navigate(-1)}
      >
        Volver
      </Button>
    </MainBox>
  );
};

export default ShopPromotions;
