import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AirplaneTicketOutlined, HomeOutlined, Logout, MenuSharp } from '@mui/icons-material';
import { Button, SwipeableDrawer } from '@mui/material';
import { useAuth } from '../../context/AuthProvider';
import { HamburgerIcon, MenuBox } from './Hamburger.styles';

const Hamburger = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { signOut } = useAuth();

  return (
    <>
      <HamburgerIcon onClick={() => setIsOpen(true)}>
        <MenuSharp />
      </HamburgerIcon>
      <SwipeableDrawer
        anchor='left'
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
      >
        <MenuBox>
          <Button
            variant='text'
            component={Link}
            to={'/home'}
            className='menu-element'
          >
            <HomeOutlined
              className='menu-element-icon'
              color='secondary'
            />
            Inicio
          </Button>
          <Button
            variant='text'
            component={Link}
            to={'/my-coupons'}
            className='menu-element'
          >
            <AirplaneTicketOutlined
              className='menu-element-icon'
              color='secondary'
            />
            Mis Cupones
          </Button>
          <Button
            variant='text'
            onClick={signOut}
            className='menu-element'
          >
            <Logout
              className='menu-element-icon'
              color='error'
            />
            Cerrar sesión
          </Button>
        </MenuBox>
      </SwipeableDrawer>
    </>
  );
};

export default Hamburger;
