import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MainBox = styled(Box)`
  background: url(/images/beer-table.webp) no-repeat center;
  background-size: auto 100%;
  height: 100vh;
  left: 0;
  top: 0;
  position: relative;
`;

export const Seal = styled(Box)`
  position: absolute;
  width: 175px;
  height: 175px;
  top: 15px;
  right: -25px;
  z-index: 2;
  background: url(/images/seal-grey-light_blue-grey.svg) no-repeat;
  background-size: contain;
`;

export const TextBox = styled(Box)`
  width: 100%;
  top: 28%;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  position: relative;
`;

export const ColorBox = styled(Box)`
  background-color: rgba(14, 57, 89, 80%);
  padding: 25px;
  width: 100%;
  align-items: center;
`;
