import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MainBox = styled(Box)`
  display: flex;
  margin-top: 25px;
  height: 65vh;
  flex-direction: column;
  overflow-y: scroll;
`;
