import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Button, Skeleton, Typography } from '@mui/material';
import { Coupon, Promotion } from '../../../globals/types';
import { usePromotion } from '../../../hooks/usePromotion';
import { CouponsBox, MainBox } from './PromotionCoupons.styles';

const PromotionCoupons = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { fetchPromotion, fetchPromotionCoupons } = usePromotion();
  const {
    data: coupons,
    isLoading: isLoadingCoupons,
    error: errorCoupons,
  } = useQuery<Coupon[]>(['shopCoupons', id], () => fetchPromotionCoupons(id as string));
  const {
    data: promotion,
    isLoading: isLoadingPromotion,
    error: errorPromotion,
  } = useQuery<Promotion>(['promotion', id], () => fetchPromotion(id as string));

  if (isLoadingCoupons || isLoadingPromotion) return <Skeleton />;
  if (errorCoupons || errorPromotion || !promotion || !coupons)
    return <Typography variant='h1'>Ups... Algo salió mal ...</Typography>;

  return (
    <MainBox>
      <Typography variant='h1'>{`${promotion.title} ${promotion.subtitle}`}</Typography>
      <CouponsBox>
        {coupons.map((coupon, i) => (
          <Button
            key={i}
            variant='outlined'
            component={Link}
            to={`/coupon-details/${coupon.slug}`}
          >
            {coupon.slug}
          </Button>
        ))}
      </CouponsBox>
      <Button
        variant='contained'
        color='secondary'
        onClick={() => navigate(-1)}
      >
        Volver
      </Button>
    </MainBox>
  );
};

export default PromotionCoupons;
