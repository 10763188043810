import { Route, Routes } from 'react-router-dom';
import CouponDetails from '../pages/shopAdmin/CouponDetails/CouponDetails';
import MyShops from '../pages/shopAdmin/MyShops/MyShops';
import PromotionCoupons from '../pages/shopAdmin/PromotionCoupons/PromotionCoupons';
import ShopPromotions from '../pages/shopAdmin/ShopPromotions/ShopPromotions';

const ShopAdminRoutes = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={<MyShops />}
      />
      <Route
        path='/shop-promotions/:id'
        element={<ShopPromotions />}
      />
      <Route
        path='/promotion-coupons/:id'
        element={<PromotionCoupons />}
      />
      <Route
        path='/coupon-details/:slug'
        element={<CouponDetails />}
      />
    </Routes>
  );
};

export default ShopAdminRoutes;
