import { useCallback } from 'react';
import { Promotion, Shop } from '../globals/types';
import api from '../services/api';

const shopsApiPath = '/shops';

const useShop = () => {
  const fetchActiveShops = async (): Promise<Shop[]> => {
    const { data } = await api.get(`${shopsApiPath}`);
    return data;
  };

  const fetchShop = async (id: string): Promise<Shop> => {
    const { data } = await api.get(`${shopsApiPath}/${id}`);
    return data;
  };

  const fetchShopBySlug = async (slug: string): Promise<Shop> => {
    const { data } = await api.get(`${shopsApiPath}/by-slug/${slug}`);
    return data;
  };

  const fetchUserShops = async (): Promise<Shop[]> => {
    const { data } = await api.get(`${shopsApiPath}/my-shops`);
    return data;
  };

  const fetchActivePromotions = async (id: string): Promise<Promotion[]> => {
    const { data } = await api.get(`${shopsApiPath}/${id}/active-promotions`);
    return data;
  };

  const fetchPromotions = async (id: string): Promise<Promotion[]> => {
    const { data } = await api.get(`${shopsApiPath}/${id}/promotions`);
    return data;
  };

  const isOpen = (shop: Shop): boolean => {
    const now = new Date();
    const nowInSeconds = now.getHours() * 3600 + now.getMinutes() * 60;

    const getValidOpeningHoursRange = (element: number[]) => {
      return element[0] <= nowInSeconds && element[1] > nowInSeconds;
    };

    switch (now.getDay()) {
      default:
        return shop?.openingHours.sundays.findIndex(getValidOpeningHoursRange) !== -1;
      case 1:
        return shop?.openingHours.mondays.findIndex(getValidOpeningHoursRange) !== -1;
      case 2:
        return shop?.openingHours.tuesdays.findIndex(getValidOpeningHoursRange) !== -1;
      case 3:
        return shop?.openingHours.wednesdays.findIndex(getValidOpeningHoursRange) !== -1;
      case 4:
        return shop?.openingHours.thursdays.findIndex(getValidOpeningHoursRange) !== -1;
      case 5:
        return shop?.openingHours.fridays.findIndex(getValidOpeningHoursRange) !== -1;
      case 6:
        return shop?.openingHours.saturdays.findIndex(getValidOpeningHoursRange) !== -1;
    }
  };

  const getOpeningHoursText = useCallback((shop: Shop) => {
    interface DayOpeningHoursText {
      day: string;
      openingHours: string[];
    }

    const castToWeekOpeningHoursText = (weekOpeningHoursText: DayOpeningHoursText[]) => {
      interface Group {
        days: string[];
        openingHours: string[];
      }

      const groups: Group[] = [];

      weekOpeningHoursText.forEach((value) => {
        if (value.openingHours.length) {
          if (
            groups.length &&
            JSON.stringify(groups[groups.length - 1].openingHours) === JSON.stringify(value.openingHours)
          ) {
            groups[groups.length - 1].days.push(value.day);
          } else {
            groups.push({
              days: [value.day],
              openingHours: value.openingHours,
            });
          }
        }
      });

      const results: string[] = [];

      groups.forEach((value) => {
        let result = '';
        if (value.openingHours.length) {
          result += value.days[0];
          if (value.days.length === 2) {
            result += ' y ' + value.days[1];
          } else if (value.days.length > 2) {
            result += ' a ' + value.days[value.days.length - 1];
          }

          value.openingHours.forEach((openingHoursGroup, index) => {
            if (index === 0) {
              result += ' ' + value.openingHours[index];
            } else if (index === openingHoursGroup.length - 1) {
              result += ' y ' + value.openingHours[index];
            } else {
              result += ', ' + value.openingHours[index];
            }
          });
        }
        results.push(result);
      });

      return results;
    };

    const castToOpeningHoursText = (day: string, openingHoursOfDay: number[][] | undefined): DayOpeningHoursText => {
      const dayOpeningTimes: string[] = [];

      const toDateString = (timeInSeconds: number) => {
        let hours = Math.floor(timeInSeconds / 3600);
        if (hours > 24) {
          hours -= 24;
        }
        let dateString = hours.toString();
        const minutes = timeInSeconds % 3600;
        if (minutes !== 0) {
          dateString += ':' + (minutes < 10 ? '0' : '') + minutes;
        }
        return dateString;
      };

      openingHoursOfDay?.forEach((openingHours) => {
        dayOpeningTimes.push('de ' + toDateString(openingHours[0]) + ' a ' + toDateString(openingHours[1]) + 'hs');
      });

      return {
        day: day,
        openingHours: dayOpeningTimes,
      };
    };

    return castToWeekOpeningHoursText([
      castToOpeningHoursText('Lun.', shop?.openingHours.mondays),
      castToOpeningHoursText('Mar.', shop?.openingHours.tuesdays),
      castToOpeningHoursText('Mie.', shop?.openingHours.wednesdays),
      castToOpeningHoursText('Jue.', shop?.openingHours.thursdays),
      castToOpeningHoursText('Vie.', shop?.openingHours.fridays),
      castToOpeningHoursText('Sab.', shop?.openingHours.saturdays),
      castToOpeningHoursText('Dom.', shop?.openingHours.sundays),
    ]);
  }, []);

  return {
    fetchActiveShops,
    fetchShop,
    fetchShopBySlug,
    fetchUserShops,
    fetchPromotions,
    fetchActivePromotions,
    isOpen,
    getOpeningHoursText,
  };
};

export { useShop };
