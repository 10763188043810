import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, AlertTitle, Button, Checkbox, FormControlLabel, Grid, Link, TextField } from '@mui/material';
import { User } from '../../../globals/types';
import AuthenticationHeader from '../../../components/AuthenticationHeader/AuthenticationHeader';
import Footer from '../../../components/Footer/Footer';
import { useAuth } from '../../../context/AuthProvider';
import { FormBox, MainBox } from './SignUp.styles';

const schema = yup.object().shape({
  age: yup.number().integer().min(18, 'Tienes que ser mayor de edad para registrarte'),
  email: yup.string().required('Campo requerido').email('Tienes que introducir un email válido'),
  password: yup
    .string()
    .required('Campo requerido')
    .min(8, 'Tu contraseña debe tener al menos 8 caracteres')
    .max(25, 'Tu contraseña puede tener como máximo 25 caracteres'),
});

const SignUp = () => {
  const navigate = useNavigate();
  const { signUp } = useAuth();
  const [apiError, setApiError] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<User>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: User) => {
    setApiError(null);
    signUp(data)
      .then(() => navigate('/home'))
      .catch((reason) => {
        if (reason.response.data.message === 'User already exists') {
          setApiError('Ya existe un usuario registrado con este correo electrónico');
        }
      });
  };

  return (
    <MainBox>
      <AuthenticationHeader />
      <FormBox
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              {...register('firstName')}
              type='text'
              autoComplete='given-name'
              name='firstName'
              required
              fullWidth
              id='firstName'
              label='Nombre'
              autoFocus
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              {...register('lastName')}
              required
              fullWidth
              id='lastName'
              label='Apellido'
              name='lastName'
              autoComplete='family-name'
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <TextField
              {...register('age')}
              required
              fullWidth
              type='number'
              id='age'
              label='Edad'
              name='age'
              autoComplete='age'
              helperText={errors.age?.message}
              error={!!errors.age?.message}
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <TextField
              {...register('gender')}
              required
              fullWidth
              type='text'
              id='gender'
              label='Sexo'
              name='gender'
              autoComplete='gender'
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <TextField
              {...register('country')}
              required
              fullWidth
              id='country'
              label='País'
              name='country'
              autoComplete='country'
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <TextField
              {...register('state')}
              required
              fullWidth
              id='state'
              label='Provincia / Estado'
              name='state'
              autoComplete='state'
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <TextField
              {...register('city')}
              required
              fullWidth
              id='city'
              label='Ciudad'
              name='city'
              autoComplete='city'
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              {...register('email')}
              required
              fullWidth
              id='email'
              label='Correo electrónico'
              name='email'
              type='email'
              autoComplete='email'
              helperText={errors.email?.message}
              error={!!errors.email?.message}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              {...register('password')}
              required
              fullWidth
              name='password'
              label='Contraseña'
              type='password'
              id='password'
              autoComplete='new-password'
              helperText={errors.password?.message}
              error={!!errors.password?.message}
            />
          </Grid>
          {apiError && (
            <Grid
              item
              xs={12}
            >
              <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                {apiError}
              </Alert>
            </Grid>
          )}
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Checkbox
                  {...register('newsletter')}
                  color='secondary'
                />
              }
              label='Deseo recibir notificaciones y promociones'
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Button
              type='submit'
              className='register-button'
              variant='contained'
            >
              Registrarme
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent='flex-end'
        >
          <Grid item>
            <Link
              component={RouterLink}
              to='/sign-in'
              variant='body2'
            >
              ¿Ya tenes una cuenta? Iniciar sesión
            </Link>
          </Grid>
        </Grid>
      </FormBox>
      <Footer />
    </MainBox>
  );
}

export default SignUp;