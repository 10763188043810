import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const TopBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 320px;
  background-color: ${(props) => props.theme.palette.primary.main};
  background-size: 180% auto;
  clip-path: ellipse(100% 90% at 70% 10%);
`;

export const Quilmes = styled(Box)`
  width: 124px;
  height: 60px;
  margin-top: 60px;
  align-items: center;
  background: url(/images/quilmes-white.svg) no-repeat;
  background-size: contain;
`;

export const ShopCategoriesBox = styled(Box)`
  padding-bottom: 25px;
  width: 82%;
  align-items: baseline;
  justify-content: space-between;
  display: flex;
`;

export const ShopCategoryBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 25px;
    min-height: 18px;
  }

  .category-title {
    font-family: Tungsten, serif;
    font-size: 21px;
    color: ${(props) => props.theme.palette.tertiary.dark};
    font-weight: 300;
    text-transform: uppercase;
  }
`;

export const LocationBand = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[300]};
  width: 100%;
  align-items: center;
  height: 38px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  position: absolute;
  top: 260px;
  box-shadow: 0px 4px 4px ${(props) => props.theme.palette.grey[800]};;
`;

export const BottomBox = styled(Box)`
  position: relative;
  min-height: calc(100vh - 320px);
  display: flex;
  flex-direction: column;
`;

export const HighlightedTicketsBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ImageBox = styled(Box)`
  width: 100%;
  height: 160px;
  background: url(/images/hero-image.webp) no-repeat;
  background-size: 110%;
  text-align: center;
  margin-bottom: 10px;
  .buttonHome {
    top: 70px;
  }
`;

export const HighlightedShopsBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  margin-bottom: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ShopImageBox = styled(Box)`
  width: 160px;
  height: 160px;
  padding: 5px;
  overflow: hidden;

  img {
    border-radius: 15px;
    max-width: 100%;
  }
`;

export const LocationBox = styled(Box)`
  width: 100%;
  margin-top: 20px;
`;

export const AddressBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
`;

export const MapsBox = styled(Box)`
  width: 100%;
  height: 200px;

  .location-point {
    width: 2em;
    height: 2em;
    color: ${(props) => props.theme.palette.primary.main};
  }
`;
