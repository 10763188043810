import styled from '@emotion/styled';
import { Box, MobileStepper } from '@mui/material';

export const MainBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.primary.main};
`;

export const TopBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 55vh;
  background: url(/images/waterfalls-rainbow.webp) no-repeat center top;
  background-size: 180% auto;
  clip-path: ellipse(150% 60% at 110% 40%);
`;

export const Quilmes = styled(Box)`
  width: 124px;
  height: 60px;
  margin-top: 20px;
  align-items: center;
  background: url(/images/quilmes.svg) no-repeat;
  background-size: contain;
`;

export const BottomBox = styled(Box)`
  position: relative;
  height: 45vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`;

export const Seal = styled(Box)`
  position: absolute;
  width: 210px;
  height: 210px;
  top: -150px;
  left: calc(50% - 105px);
  z-index: 2;
  background: url(/images/seal-yellow-blue-white.svg) no-repeat;
  background-size: contain;
`;

export const TextBox = styled(Box)`
  padding-bottom: 25px;
  max-width: 90%;
  align-items: center;
  justify-content: center;
`;

export const Stepper = styled(MobileStepper)`
  background: none;
  position: relative;

  .MuiMobileStepper-dot {
    background-color: ${(props) => props.theme.palette.secondary.dark};
  }

  .MuiMobileStepper-dotActive {
    background-color: ${(props) => props.theme.palette.secondary.light};
  }
`;
