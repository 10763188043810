import { useNavigate, useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import Footer from '../../../components/Footer/Footer';
import Hamburger from '../../../components/Hamburger/Hamburger';
import PromotionHeader from '../../../components/PromotionHeader/PromotionHeader';
import { useCoupon } from '../../../hooks/useCoupon';
import { useAuth } from '../../../context/AuthProvider';
import { BottomBox } from './Promotion.styles';

const Promotion = () => {
  const { id } = useParams();
  if (!id) return <Typography variant='h1'>Ups... Por favor introduce una Promo válida...</Typography>;

  const navigate = useNavigate();
  const { user } = useAuth();
  const { generateCoupon } = useCoupon();

  const handleRedeemPromotion = async () => {
    if (!user || !user._id || !id) throw new Error('Usuario o promoción inválida');
    const coupon = await generateCoupon(user._id, id);
    if (!coupon) throw new Error('Hubo un error generando su cupón');
    navigate(`/coupons/${coupon._id}`);
  };

  return (
    <>
      <Hamburger />
      <PromotionHeader promotionId={id} />
      <BottomBox>
        <Button
          variant='contained'
          className='redeem-button'
          onClick={handleRedeemPromotion}
        >
          Canjear
        </Button>
        <Footer />
      </BottomBox>
    </>
  );
};

export default Promotion;
