import { Coupon, Promotion } from '../globals/types';
import api from '../services/api';

const promotionsApiPath = '/promotions';

const usePromotion = () => {
  const fetchPromotion = async (id: string): Promise<Promotion> => {
    const { data } = await api.get(`${promotionsApiPath}/${id}`);
    return data;
  };

  // TODO: Create a way (API) to get highlighted promotions
  const fetchHighlightedPromotions = async (): Promise<Promotion[]> => {
    const { data } = await api.get(`${promotionsApiPath}`);
    return data;
  };

  const fetchPromotionCoupons = async (id: string): Promise<Coupon[]> => {
    const { data } = await api.get(`${promotionsApiPath}/${id}/coupons`);
    return data;
  };

  return { fetchPromotion, fetchHighlightedPromotions, fetchPromotionCoupons };
};

export { usePromotion };
