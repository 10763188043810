import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const HamburgerIcon = styled(Box)`
  position: absolute;
  top: 25px;
  left: 25px;
  height: 25px;
  width: 25px;
  color: white;
  z-index: 1;
`;

export const MenuBox = styled(Box)`
  margin-top: 25px;
  width: 200px;

  .menu-element {
    margin-bottom: 20px;
    width: 100%;
  }

  .menu-element-icon {
    margin: 0 10px 5px 0;
  }
`;
