import styled from '@emotion/styled';
import { Box, Button } from '@mui/material';

export const TopBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 35vh;
  background-color: ${(props) => props.theme.palette.primary.main};
  background-size: 180% auto;
  clip-path: ellipse(100% 90% at 5% 10%);
`;

export const ButtonsBox = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 350px;
  text-align: center;
  justify-content: center;
  position: absolute;
  top: 22vh;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.25);

  .top-box-button {
    border-radius: 0;
    font-size: 30px;
    letter-spacing: -0.02em;
  }
`;

export const MyCouponsButton = styled(Button)`
  color: ${(props) => props.theme.palette.tertiary.dark};
  padding: auto 3px;
  flex-basis: 36%;
`;

export const ActiveCouponsButton = styled(Button)`
  color: white;
  font-family: Tungsten, serif;
  font-weight: lighter;
  flex-basis: 32%;
`;

export const InactiveCouponsButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.background.paper};
  font-family: Tungsten, serif;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.grey[800]};
  font-weight: lighter;
  flex-basis: 32%;

  :hover {
    background-color: #d8d8d8;
  }
`;

export const Quilmes = styled(Box)`
  width: 124px;
  height: 60px;
  margin-top: 60px;
  align-items: center;
  background: url(/images/quilmes-white.svg) no-repeat;
  background-size: contain;
`;

export const BottomBox = styled(Box)`
  position: relative;
  height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 20px;
`;

export const PlaneBox = styled(Box)`
  width: 66%;
  justify-content: center;
  border-top: 1px dotted #bbb;
  text-decoration: line-through;
  flex-direction: row;
  display: flex;

  .airplane-icon {
    color: ${(props) => props.theme.palette.tertiary.dark};
    transform: rotate(45deg);
    position: absolute;
    top: -15px;
    &.active {
      left: 11%;
    }
    &.inactive {
      right: 18%;
    }
  }

  .circle-text {
    text-align: center;
    justify-content: center;
    position: absolute;
    font-size: 22px;
    font-family: 'Tungsten';
    color: white;
    width: 30px;
    height: 30px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #c5c5c5;
    top: -15px;
    right: 10%;
  }
`;

export const TicketsBox = styled(Box)`
  height: 65vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-top: 25px;
  padding-bottom: 15px;
  &::-webkit-scrollbar {
    display: none;
  }

  .inactive-coupons-title {
    font-family: Tungsten, serif;
    font-size: 36px;
    text-transform: uppercase;
    color: ${(props) => props.theme.palette.grey[600]};
    font-weight: lighter;
    text-align: left;
    line-height: 35px;
    opacity: 1.5;
  }

  .redeemed {
    margin-top: 25px;
  }
`;
