import { Skeleton } from '@mui/material';
import { TicketSkeletonWrapper } from './TicketSkeleton.styles';

const TicketSkeleton = () => {
  return (
    <TicketSkeletonWrapper>
      <Skeleton
        variant='rounded'
        animation='wave'
        width={300}
        height={150}
      />
    </TicketSkeletonWrapper>
  );
};

export default TicketSkeleton;
