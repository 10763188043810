import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MainBox = styled(Box)`
  margin: 10px 7px;
  width: 300px;
  display: flex;
  flex-direction: row;

  &.inactive {
    opacity: 0.5;
  }
`;

export const TicketLeftSide = styled(Box)`
  width: 210px;
  height: 150px;
  border-radius: 15px;
  background: url(/images/beer-and-pizza.webp) no-repeat right;
  background-size: 125%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 2px dotted ${(props) => props.theme.palette.grey[600]};;

  .redeem-ticket {
    font-size: 11px;
    height: 21px;
    padding: 0 7px;
    transform: rotate(-90deg);
    position: absolute;
    right: -25px;
    bottom: 60px;
  }
`;

export const TopBand = styled(Box)`
  width: 100%;
  height: 25px;
  background-color: rgba(54, 54, 54, 0.45);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;

  .place-icon {
    padding: 2px 5px 2px 0;
  }
`;

export const CrownBox = styled(Box)`
  width: 100px;
  height: 100px;
  margin-top: 25px;
  background: url('/images/crown-cap.svg') no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`;

export const TicketRightSide = styled(Box)`
  width: 90px;
  height: 150px;
  border-radius: 15px;
  background-color: ${(props) => props.theme.palette.secondary.main};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    width: auto;
    margin-left: 10px;
    margin-right: 4px;
  }
`;

export const TicketRightSideShopNameBox = styled(Box)`
  width: 38px;
  height: 125px;
  position: relative;

  .due-date {
    position: absolute;
    bottom: 42px;
    left: -38px;
    height: 38px;
    width: 121px;
    transform: rotate(-90deg);
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.01em;
    line-height: 19px;
    font-weight: 326;
    color: white;
  }
`;

export const BottomBand = styled(Box)`
  width: 100%;
  height: 25px;
  background-color: rgba(255, 255, 255, 0.6);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
`;
