import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MainBox = styled(Box)`
  overflow: hidden;
  background: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0) 40%), url(/images/waterfalls-mist.webp);
  background-size: cover;
`;

export const TopBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 65vh;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  .MuiFormControl-root {
    margin-top: 20px;
    width: 90%;
  }
`;

export const QuilmesCervezaArgentina = styled(Box)`
  width: 247px;
  height: 120px;
  margin-top: 90px;
  align-items: center;
  background: url(/images/quilmes-cerveza-argentina.svg) no-repeat;
  background-size: contain;
`;

export const BottomBox = styled(Box)`
  position: relative;
  background-color: rgba(14, 57, 89, 0.6);
  height: 35vh;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-top: 2px dotted #ffff;

  .MuiFab-root {
    margin: 0 5px 0 5px;
  }
`;

export const Seal = styled(Box)`
  width: 210px;
  height: 210px;
  position: absolute;
  right: -40px;
  top: -140px;
  background: url(/images/seal-blue-light_blue-grey.svg) no-repeat;
  background-size: cover;
`;

export const TextBox = styled(Box)`
  padding-bottom: 20px;
  max-width: 90%;
  align-items: center;
  justify-content: center;
`;
