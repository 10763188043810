import { Link as RouterLink } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import Footer from '../../../components/Footer/Footer';
import { BottomBox, MainBox, Quilmes, Seal, Stepper, TextBox, TopBox } from './Introduction1.styles';

const Introduction1 = () => {
  return (
    <MainBox>
      <TopBox>
        <Quilmes />
      </TopBox>
      <BottomBox>
        <Seal />
        <TextBox>
          <Typography
            variant='h1'
            color='white'
          >
            Pasaporte Quilmes
          </Typography>
          <Typography
            variant='h4'
            color='white'
          >
            Te ayudaremos a encontrar el lugar que estas buscando y además te daremos una promoción para que uses en ese
            lugar.
          </Typography>
        </TextBox>
        <Stepper
          activeStep={0}
          backButton={null}
          nextButton={null}
          variant='dots'
          steps={3}
        />
        <Button
          variant='contained'
          color='secondary'
          component={RouterLink}
          to='/introduction-2'
        >
          Siguiente
        </Button>
        <Footer />
      </BottomBox>
    </MainBox>
  );
}

export default Introduction1;