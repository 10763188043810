import { useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonBase, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { Credentials } from '../../../globals/types';
import AuthenticationHeader from '../../../components/AuthenticationHeader/AuthenticationHeader';
import Footer from '../../../components/Footer/Footer';
import { useAuth } from '../../../context/AuthProvider';
import { ForgotPasswordLink, FormBox, MainBox, SSOBox, SSODivider } from './SignIn.styles';

const schema = yup.object().shape({
  email: yup.string().required('Campo requerido').email('Tienes que introducir un email válido'),
  password: yup
    .string()
    .required('Campo requerido')
    .min(8, 'Tu contraseña debe tener al menos 8 caracteres')
    .max(25, 'Tu contraseña puede tener como máximo 25 caracteres'),
});

const SignIn = () => {
  const navigate = useNavigate();
  const { signIn, signOut } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Credentials>({ resolver: yupResolver(schema) });

  const onSubmit = (credentials: Credentials) => {
    signIn(credentials).then(() => navigate('/home'));
  };

  return (
    <MainBox>
      <AuthenticationHeader />
      <FormBox
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          {...register('email')}
          margin='normal'
          required
          fullWidth
          id='email'
          label='Correo electrónico'
          name='email'
          autoComplete='email'
          type='email'
          helperText={errors.email?.message}
          error={!!errors.email?.message}
          autoFocus
        />
        <TextField
          {...register('password')}
          margin='dense'
          required
          fullWidth
          name='password'
          label='Contraseña'
          type='password'
          id='password'
          autoComplete='current-password'
          helperText={errors.password?.message}
          error={!!errors.password?.message}
        />
        <FormControlLabel
          control={
            <Checkbox
              value='remember'
              color='secondary'
            />
          }
          label='Recuérdame'
        />
        <ForgotPasswordLink
          href='#'
          variant='body1'
        >
          ¿Te olvidaste tu contraseña?
        </ForgotPasswordLink>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          className='login-button'
        >
          Ingresar
        </Button>
        <Button
          component={RouterLink}
          to='/sign-up'
          fullWidth
          variant='outlined'
          className='register-button'
        >
          Registrarse
        </Button>
      </FormBox>
      <SSOBox>
        <SSODivider>o registrate con</SSODivider>
        <Grid container>
          <Grid
            item
            xs={3}
          >
            <ButtonBase>
              <img
                alt='Google'
                src={'/images/google-icon.svg'}
              />
            </ButtonBase>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <ButtonBase>
              <img
                alt='Facebook'
                src={'/images/facebook-icon.svg'}
              />
            </ButtonBase>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <ButtonBase onClick={signOut}>
              <img
                alt='Apple'
                src={'/images/apple-icon.svg'}
              />
            </ButtonBase>
          </Grid>
        </Grid>
      </SSOBox>
      <Footer />
    </MainBox>
  );
};

export default SignIn;
