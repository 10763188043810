import GoogleMapReact from 'google-map-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { PlaceOutlined } from '@mui/icons-material';
import { Box, Fab, Typography } from '@mui/material';
import { Promotion, Shop } from '../../../globals/types';
import Footer from '../../../components/Footer/Footer';
import Hamburger from '../../../components/Hamburger/Hamburger';
import SearchAppBar from '../../../components/Search/Search';
import Ticket from '../../../components/Ticket/Ticket';
import MyHomeSkeleton from '../../../components/skeletons/HomeSkeleton/HomeSkeleton';
import { usePromotion } from '../../../hooks/usePromotion';
import { useShop } from '../../../hooks/useShop';
import {
  AddressBox,
  BottomBox,
  HighlightedShopsBox,
  HighlightedTicketsBox,
  ImageBox,
  LocationBand,
  LocationBox,
  MapsBox,
  Quilmes,
  ShopCategoriesBox,
  ShopCategoryBox,
  ShopImageBox,
  TopBox,
} from './Home.styles';

interface HomeProps {
  shops: Shop[];
  highlightedPromotions: Promotion[];
}

const Home = () => {
  const navigate = useNavigate();
  const { fetchHighlightedPromotions } = usePromotion();
  const { fetchActiveShops } = useShop();
  const { data, isLoading, error } = useQuery<HomeProps>(['fetchHighlightedPromotions'], () => fetchData(), {
    staleTime: 600000,
  });

  const coords = {
    center: {
      lat: -25.611152,
      lng: -54.568739,
    },
    zoom: 13,
  } as GoogleMapReact.Props;

  const [mapsApiKey] = useState<string>(process.env.REACT_APP_MAPS_API_KEY || '');
  const fetchData = async (): Promise<HomeProps> => {
    return {
      shops: await fetchActiveShops(),
      highlightedPromotions: await fetchHighlightedPromotions(),
    };
  };

  if (isLoading) return <MyHomeSkeleton />;
  if (error || !data) return <Typography variant='h1'>Ups... Algo salió mal ...</Typography>;

  return (
    <>
      <Hamburger />
      <TopBox>
        <Quilmes />
        <SearchAppBar shops={data.shops}></SearchAppBar>
        <ShopCategoriesBox>
          <ShopCategoryBox>
            <img
              alt='Atracción'
              src={'/images/attraction.svg'}
            />
            <Typography className='category-title'>Atracción</Typography>
          </ShopCategoryBox>
          <ShopCategoryBox>
            <img
              alt='Pizzería'
              src={'/images/pizza-shop.svg'}
            />
            <Typography className='category-title'>Pizzería</Typography>
          </ShopCategoryBox>
          <ShopCategoryBox>
            <img
              alt='Hotel'
              src={'/images/hotel.svg'}
            />
            <Typography className='category-title'>Hotel</Typography>
          </ShopCategoryBox>
          <ShopCategoryBox>
            <img
              alt='Restaurant'
              src={'/images/restaurant.svg'}
            />
            <Typography className='category-title'>Restaurant</Typography>
          </ShopCategoryBox>
          <ShopCategoryBox>
            <img
              alt='Bar'
              src={'/images/bar.svg'}
            />
            <Typography className='category-title'>Bar</Typography>
          </ShopCategoryBox>
          <ShopCategoryBox>
            <img
              alt='Parrilla'
              src={'/images/grill.svg'}
            />
            <Typography className='category-title'>Parrilla</Typography>
          </ShopCategoryBox>
        </ShopCategoriesBox>
      </TopBox>
      <LocationBand>
        <PlaceOutlined className='place-icon' />
        <Typography
          variant='h5'
          color='black'
        >
          Puerto Iguazú, Misiones, Argentina
        </Typography>
      </LocationBand>
      <BottomBox>
        <Typography
          variant='h1'
          color='primary'
          fontSize='29px'
          marginLeft='10%'
        >
          Promociones Destacadas
        </Typography>
        <HighlightedTicketsBox>
          {data.highlightedPromotions.map((promotion, i) => (
            <Ticket
              key={i}
              promotion={promotion}
            />
          ))}
        </HighlightedTicketsBox>
        <ImageBox>
          <Typography
            variant='h2'
            color='white'
          >
            Conocé Parque Nacional Iguazú
          </Typography>
          <Fab
            className='buttonHome'
            variant='extended'
          >
            Descubrir
          </Fab>
        </ImageBox>
        <Box>
          <Typography
            variant='h1'
            fontSize='29px'
            marginLeft='10%'
          >
            Más Experiencias...
          </Typography>
        </Box>
        <HighlightedShopsBox>
          {data.shops.map((shop, i) => (
            <ShopImageBox
              key={i}
              onClick={() => navigate(`/shops/${shop.slug}`)}
            >
              <img
                alt={shop.name}
                src={shop.otherImages[0]}
              />
            </ShopImageBox>
          ))}
        </HighlightedShopsBox>
        <LocationBox>
          <AddressBox>
            <PlaceOutlined />
            <Typography>Puerto Iguazú, Misiones, Argentina</Typography>
          </AddressBox>
          <MapsBox>
            <GoogleMapReact
              bootstrapURLKeys={{ key: mapsApiKey }}
              defaultCenter={coords?.center}
              defaultZoom={coords?.zoom}
              zoom={coords?.zoom}
              yesIWantToUseGoogleMapApiInternals
            ></GoogleMapReact>
          </MapsBox>
        </LocationBox>
        <Footer />
      </BottomBox>
    </>
  );
};

export default Home;
