import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import Footer from '../../../components/Footer/Footer';
import { ColorBox, MainBox, Seal, TextBox } from './Success.styles';

const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => navigate('/home'), 3000);
  });

  return (
    <MainBox>
      <Seal />
      <TextBox>
        <Typography
          variant='h6'
          color='white'
        >
          GRACIAS!!!
        </Typography>
        <ColorBox>
          <Typography
            variant='h2'
            color='white'
          >
            Seguí disfrutando de más <b>clásicos</b> Argentinos...
          </Typography>
        </ColorBox>
      </TextBox>
      <Footer />
    </MainBox>
  );
};

export default Success;
